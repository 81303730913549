/*
    POINT D'ENTRÉE BRIKS HTML
    Placer le chemin du fichier dans le fichier _config.yml de LibDoc
*/

/*
    CORE
    Fonctionnalités principales 
    Inclure briks/_core.scss pour obtenir les fonctions helpers et les mixins.
    Aucune déclaration CSS n'est générée à partir de ce fichier.
*/
@import 'briks/core';
/*
    DESIGN TOKENS
    Fichier SASS contenant les design tokens du projet
*/
@import 'briks/settings/tokens/ucar';
/*
    HTML
    Requiert briks/_core.scss
    Déclarations CSS appliquées sur <body>
*/
body {
    font-family: my-font-family(lead-400);
    background-color: my-color(grey-light-100);
    color: my-color(grey-dark-900);
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
